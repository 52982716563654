import localizeFilter from '@/filter/localize.filter'
export default function GetCatalitycChange(id){
    return axios.get('/change.php', 
        {
            params: 
            {
                type: 1,
                id: id
            }
        })
        .then(response => {
            if(response.data != -10){
                this.$data.selectedMark = {value: response.data[0].valuemark, text: response.data[0].textmark}
                this.$data.selectedModel = {value: response.data[0].valuemodel, text: response.data[0].textmodel}
                this.$data.selectedGeneration = {value: response.data[0].valuegeneration, text: response.data[0].textgeneration}
                this.$data.selectedYear = response.data[0].year
                this.$data.selectedCountry = {value: response.data[0].valuecountry, text: response.data[0].textcountry}
                this.$data.selectedEngine_code = response.data[0].engine_code
                this.$data.selectedCatalic_code = response.data[0].catalic_code
                this.$data.selectedType = response.data[0].type
                this.$data.selectedWeight = response.data[0].weight
                this.$data.selectedEngineCode = response.data[0].engine_code
                this.$data.selectedEngineVolume = response.data[0].engine == null ? '' : response.data[0].engine
                this.$data.selectedEngineType = response.data[0].engine_type
                this.$data.description = response.data[0].specs
                this.$data.comment = response.data[0].comment
                this.$data.pt = response.data[0].pt == null ? 0 : response.data[0].pt
                this.$data.pd = response.data[0].pd == null ? 0 : response.data[0].pd
                this.$data.rh = response.data[0].rh == null ? 0 : response.data[0].rh
                this.$data.idavto = response.data[0].idavto
                this.$data.tags = response.data[0].tags
               
                this.$data.loadedUrls = Object.values(response.data[0].images)
                //this.$data.deletedUrls = Object.values(response.data[0].images)
                //this.$data.allcountries = response.data;
            }else{
                UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
            }
            //this.$Progress.finish()
        })
        .catch(function(error){
            console.log(error)
            UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
            //this.$Progress.fail()
        })
}